import { SwiftForm } from 'swift-tools'

const DeepstackFormApplication = (props) => {
  const { type, layout, theme, brand_color, onComplete, submit, formSubmitRef } = props
  return (
    <>
      {layout === 'inline' ? (
        <></>
      ) : (
        <SwiftForm
          onSubmit={(vals) => onComplete(vals)}
          steps={3}
          gridCols={12}
          submitRef={formSubmitRef}
          type={type}
          theme={theme}
          fields={[
            {
              key: '1a',
              type: 'title',
              label: '1. General Information',
              step: 1,
            },

            {
              key: '1',
              type: 'text',
              label: 'Business Name',
              placeholder: '',
              value: '',
              validate: true,
              step: 1,
              gridColSpan: 6,
            },

            {
              key: '2',
              type: 'text',
              label: 'Corporate/Legal Name',
              placeholder: '',
              value: '',
              step: 1,
              gridColSpan: 6,
            },

            {
              key: '3',
              type: 'text',
              label: 'Contact Name',
              placeholder: '',
              value: '',
              step: 1,
              gridColSpan: 6,
            },

            {
              key: '4',
              type: 'text',
              label: 'Contact Phone',
              placeholder: '',
              value: '',
              step: 1,
              gridColSpan: 6,
            },

            {
              key: '5',
              type: 'text',
              label: 'Business Email',
              placeholder: '',
              value: '',
              step: 1,
              gridColSpan: 6,
            },

            {
              key: '6',
              type: 'text',
              label: 'Business Website Address',
              placeholder: '',
              value: '',
              step: 1,
              gridColSpan: 6,
            },

            ...(submit
              ? [
                  {
                    key: 'step1-submit',
                    type: 'submit',
                    icon: 'next',
                    color: brand_color,
                    flip: true,
                    label: 'Continue',
                    step: 1,
                  },
                ]
              : []),

            {
              key: '6b',
              type: 'title',
              label: '2. Business Location',
              step: 2,
            },

            {
              key: '6c',
              type: 'subtitle',
              label: 'Contact Details',
              step: 2,
            },
            {
              key: '11',
              type: 'text',
              label: 'Location Phone',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,
            },

            {
              key: '12',
              type: 'text',
              label: 'Location Fax',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,
            },
            {
              key: '17',
              type: 'text',
              label: 'Customer Service Phone',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,
            },

            {
              key: '18',
              type: 'text',
              label: 'Customer Service Fax',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,
            },

            { key: '6d', type: 'subtitle', label: 'Addresses', step: 2 },

            {
              key: '7',
              type: 'text',
              label: 'Location Address',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,
            },
            {
              key: '8',
              type: 'text',
              label: 'City',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 2,
            },
            {
              key: '9',
              type: 'text',
              label: 'State',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 2,
            },
            {
              key: '10',
              type: 'text',
              label: 'Zip',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 2,
            },

            {
              type: 'checkbox',
              key: '13a',
              label: 'Separate Corporate Address',
              step: 2,
            },

            {
              key: '13',
              type: 'text',
              label: 'Corporate Address',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,
              gate: {
                field: '13a',
                value: 1,
              },
            },
            {
              key: '14',
              type: 'text',
              label: 'City',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 2,
              gate: {
                field: '13a',
                value: 1,
              },
            },
            {
              key: '15',
              type: 'text',
              label: 'State',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 2,
              gate: {
                field: '13a',
                value: 1,
              },
            },
            {
              key: '16',
              type: 'text',
              label: 'Zip',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 2,
              gate: {
                field: '13a',
                value: 1,
              },
            },

            {
              type: 'checkbox',
              key: '19',
              label: 'Additional Locations?',
              step: 2,
            },

            {
              key: '20',
              type: 'text',
              label: 'How Many Locations?',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,

              gate: {
                field: '19',
                value: 1,
              },
            },
            {
              key: '21',
              type: 'text',
              label: 'Additional Location to Existing MID',
              placeholder: '',
              value: '',
              step: 2,
              gridColSpan: 6,
              gate: {
                field: '19',
                value: 1,
              },
            },

            { key: '6e', type: 'subtitle', label: 'Preferences', step: 2 },
            {
              key: '22',
              type: 'select',
              label: 'Send retrieval/chargeback requests to',
              placeholder: '',
              values: [{ name: 'Location Address' }, { name: 'Corporate Address' }],
              value: '',
              step: 2,
              gridColSpan: 6,
              validate: true,
            },

            {
              key: '23',
              type: 'select',
              label: 'Send monthly merchant statements to',
              placeholder: '',
              values: [{ name: 'Location Address' }, { name: 'Corporate Address' }],
              value: '',
              step: 2,
              gridColSpan: 6,
              validate: true,
            },

            ...(submit
              ? [
                  {
                    key: 'step2-back',
                    type: 'button',
                    action: 'back',
                    icon: 'back',
                    color: '#c1c1c1',
                    fillType: 'light',
                    label: 'Back',
                    step: 2,
                    gridColSpan: 2,
                  },
                  {
                    key: 'step2-submit',
                    type: 'submit',
                    icon: 'next',
                    color: brand_color,
                    flip: true,
                    label: 'Continue',
                    step: 2,
                    gridColSpan: 2,
                  },
                ]
              : []),

            {
              type: 'title',
              key: '24',
              label: '3. Business Structure',
              step: 3,
            },
            {
              key: '25',
              type: 'text',
              label: 'Tax Filing Name',
              placeholder: '',
              value: '',
              step: 3,
              gridColSpan: 6,
            },
            {
              key: '26',
              type: 'text',
              label: 'Federal Tax ID',
              sublabel: '^^ Must match IRS Income Tax Filing',
              placeholder: '',
              value: '',
              step: 3,
              gridColSpan: 6,
            },

            {
              key: '27',
              type: 'text',
              label: 'Tax Type',
              placeholder: '',
              value: '',
              step: 3,
              gridColSpan: 6,
            },

            {
              key: '28',
              type: 'text',
              label: 'D&B #',
              placeholder: '',
              value: '',
              step: 3,
              gridColSpan: 6,
            },

            {
              type: 'checkbox',
              key: '29',
              label: 'Prior Security Breach?',
              step: 3,
            },

            ...(submit
              ? [
                  {
                    key: 'step3-back',
                    type: 'button',
                    action: 'back',
                    color: '#c1c1c1',
                    fillType: 'light',
                    label: 'Back',
                    icon: 'back',
                    step: 3,
                    gridColSpan: 2,
                  },
                  {
                    key: 'step3-submit',
                    type: 'submit',
                    label: 'Continue',
                    icon: 'next',
                    color: brand_color,
                    flip: true,
                    step: 3,
                    gridColSpan: 2,
                  },
                ]
              : []),
          ]}
        />
      )}
    </>
  )
}
export default DeepstackFormApplication
