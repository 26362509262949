import { SwiftForm } from 'swift-tools'

const DeepstackFormCard = (props) => {
	const { type, layout, theme, brand_color, onComplete, submit, formSubmitRef } = props

	return (
		<>
			{layout === 'inline' ? (
				<SwiftForm
					onSubmit={(vals) => onComplete(vals)}
					inline={true}
					theme={theme}
					type={type}
					submitRef={formSubmitRef}
					fields={[
						{
							key: 'card_number',
							type: 'text',
							label: 'Card Number',
							validate: 'card_number',
							mask: 'card_number',
							placeholder: '____ ____ ____ ____',
							value: '',
							flex: 2,
						},

						{
							key: 'card_expiration',
							type: 'text',
							label: 'Card Expiration',
							mask: 'card_expiration',
							validate: 'card_expiration',
							placeholder: theme === 'minimal' ? 'MM / YY' : '__ / __',
							value: '',
							flex: 1,
						},
						{
							key: 'card_cvv',
							type: 'text',
							label: 'CVV',
							mask: 'card_cvv',
							validate: 'card_cvv',
							placeholder: theme === 'minimal' ? 'CVV' : '___',
							value: '',
							flex: 1,
						},

						{
							key: 'card_billing_zipcode',
							type: 'text',
							label: 'Zip Code',
							mask: 'zip_code',
							validate: 'zip_code',
							placeholder: theme === 'minimal' ? 'Zip Code' : '_____',
							value: '',
							flex: 1,
						},
						...(submit
							? [
									{
										key: 'submit',
										type: 'submit',
										label: 'Submit',
										color: brand_color,
									},
							  ]
							: []),
					]}
				/>
			) : (
				<SwiftForm
					onSubmit={(vals) => onComplete(vals)}
					submitRef={formSubmitRef}
					theme={theme}
					type={type}
					fields={[
						{ type: 'subtitle', label: 'Basic Info' },
						{
							key: 'card_name',
							type: 'text',
							label: 'Full Name',
							value: '',
							validate: true,
							//autoFocus: true,
						},
						{
							key: 'card_phone',
							type: 'text',
							label: 'Phone',
							value: '',
							validate: 'phone',
							mask: 'phone',
							gridColSpan: 2,
						},
						{
							key: 'card_email',
							type: 'text',
							label: 'Email',
							value: '',
							validate: 'email',
							gridColSpan: 2,
						},

						{ type: 'subtitle', label: 'Card Info' },

						{
							key: 'card_number',
							validate: 'card_number',
							mask: 'card_number',
							type: 'text',
							label: 'Card Number',
							placeholder: '____ ____ ____ ____',
							value: '',
							gridColSpan: 2,
						},

						{
							key: 'card_expiration',
							type: 'text',
							label: 'Expires',
							mask: 'card_expiration',
							validate: 'card_expiration',
							placeholder: '__ / __',
							value: '',
							gridColSpan: 1,
						},
						{
							key: 'card_cvv',
							type: 'text',
							label: 'CVV',
							mask: 'card_cvv',
							validate: 'card_cvv',
							placeholder: '___',
							value: '',
							gridColSpan: 1,
						},

						{ type: 'subtitle', label: 'Billing Address' },

						{
							key: 'card_billing_address',
							type: 'text',
							label: 'Address',
							placeholder: '',
							value: '',
							gridColSpan: 2,
						},

						{
							key: 'card_billing_zipcode',
							type: 'text',
							label: 'Zip Code',
							mask: 'zip_code',
							validate: 'zip_code',
							placeholder: '_____',
							value: '',
							gridColSpan: 2,
						},
						...(submit
							? [
									{
										key: 'submit',
										type: 'submit',
										label: 'Submit',
										color: brand_color,
									},
							  ]
							: []),
					]}
				/>
			)}
		</>
	)
}
export default DeepstackFormCard
