import { SwiftForm } from 'swift-tools'

const DeepstackFormACH = (props) => {
	const { type, layout, theme, brand_color, onComplete, submit, formSubmitRef } = props
	return (
		<>
			{layout === 'inline' ? (
				<SwiftForm
					onSubmit={(vals) => onComplete(vals)}
					inline={true}
					type={type}
					theme={theme}
					submitRef={formSubmitRef}
					fields={[
						{
							key: 'ach_name',
							type: 'text',
							label: 'Name on Account',
							placeholder: 'John Doe',
							value: '',
							validate: true,
							flex: 1,
						},
						{
							key: 'ach_type',
							type: 'select',
							label: 'Account Type',
							placeholder: '',
							emptyValue: false,
							value: 'checking',
							values: [
								{ name: 'Checking Account', value: 'checking' },
								{ name: 'Savings Account', value: 'savings' },
							],
							validate: true,
							flex: 1,
						},

						{
							key: 'ach_routing',
							type: 'text',
							label: 'Routing Number',
							value: '',
							mask: 'ach_routing',
							placeholder: theme === 'minimal' ? 'Routing #' : '_________',
							validate: 'ach_routing',
							flex: 1,
						},
						{
							key: 'ach_account',
							type: 'text',
							label: 'Account Number',
							placeholder: theme === 'minimal' ? 'Account #' : '',
							value: '',
							validate: 'ach_account',
							flex: 1,
						},
						...(submit
							? [
									{
										key: 'submit',
										type: 'submit',
										color: brand_color,
										label: 'Submit',
									},
							  ]
							: []),
					]}
				/>
			) : (
				<SwiftForm
					onSubmit={(vals) => onComplete(vals)}
					submitRef={formSubmitRef}
					type={type}
					theme={theme}
					fields={[
						{ type: 'subtitle', label: 'Personal Info', key: '1a' },
						{
							key: 'name',
							type: 'text',
							label: 'Full Name',
							value: '',
							validate: true,
							//autoFocus: true,
						},
						{
							key: 'phone',
							type: 'text',
							label: 'Phone',
							value: '',
							validate: 'phone',
							gridColSpan: 2,
						},
						{
							key: 'email',
							type: 'text',
							label: 'Email',
							value: '',
							validate: 'phone',
							gridColSpan: 2,
						},
						{ type: 'subtitle', label: 'Account Info', key: '2a' },
						{
							key: 'ach_name',
							type: 'text',
							label: 'Name on Account',
							placeholder: '',
							value: '',
							validate: true,
							gridColSpan: 2,
						},
						{
							key: 'ach_type',
							type: 'select',
							label: 'Account Type',
							placeholder: '',
							emptyValue: false,
							value: 'checking',
							values: [
								{ name: 'Checking', value: 'checking' },
								{ name: 'Savings', value: 'savings' },
							],
							validate: true,
							gridColSpan: 2,
						},

						{
							key: 'ach_routing',
							type: 'text',
							label: 'Routing Number',
							value: '',
							mask: 'ach_routing',
							placeholder: '_________',
							validate: 'ach_routing',
							gridColSpan: 2,
						},
						{
							key: 'ach_account',
							type: 'text',
							label: 'Account Number',
							value: '',
							validate: 'ach_account',
							gridColSpan: 2,
						},

						...(submit
							? [
									{
										key: 'submit',
										type: 'submit',
										color: brand_color,
										label: 'Submit',
									},
							  ]
							: []),
					]}
				/>
			)}
		</>
	)
}
export default DeepstackFormACH
