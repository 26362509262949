import React from 'react'
import ReactDOM from 'react-dom'
import { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import useSize from '@react-hook/size'
//import { _widgetInitialize, _widgetSubmit } from './ajax'
import { createGlobalStyle } from 'styled-components'
import BarLoader from 'react-spinners/BarLoader'
import DeepstackForm from './forms'
import axios from 'axios'

const GlobalStyle = createGlobalStyle`
  body {
    overflow:hidden;
  }
`

const AppStyled = styled.div`
	overflow: hidden;

	#deepstack-forms-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		#deepstack-form {
			flex: 1;
			max-width: 920px;
			width: 100%;
			background: #fff;
		}

		#deepstack-form-loading,
		#deepstack-form-message {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 120px;
			> span {
				width: 100%;
				display: block;
				min-width: 80px;
			}
			h4,
			h6 {
				font-weight: 800;
				font-family: Lato, sans-serif;
				font-size: 22px;
				line-height: 24px;
				margin: 0;
			}
			h6 {
				font-size: 16px;
				line-height: 20px;
				font-weight: 600;
				opacity: 0.8;
				margin-top: 0.33em;
			}
		}

		#deepstack-form-message {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
			background: rgba(255, 255, 255, 0.9);
			backdrop-filter: blur(2px);
			min-height: 0;
			#deepstack-form-message-content {
				display: flex;
				align-items: center;
				i {
					svg {
						width: 56px;
						height: 56px;
						display: block;
						fill: ${(props) => props.brand_color};
					}
				}
				#deepstack-form-message-text {
					margin-left: 18px;
				}
			}
		}
	}
`

const App = (props) => {
	const appRef = useRef()
	const formSubmitRef = useRef()

	const [width, height] = useSize(appRef)

	const [riskData, setRiskData] = useState(null)

	const urlParams = new URLSearchParams(window.location.search)
	const env = urlParams.get('env') ? urlParams.get('env') : 'local'
	const config = {
		session_id: urlParams.get('session_id'),
		api_key: urlParams.get('api_key'),
		session_id: urlParams.get('session_id'),
		type: urlParams.get('type'),
		layout: urlParams.get('layout'),
		theme: urlParams.get('theme'),
		brand_color: urlParams.get('brand_color'),
		css: urlParams.get('css'),
		submit: urlParams.get('submit') === 'true' ? 1 : 0,
		options: JSON.parse(urlParams.get('options')),
		success_header: urlParams.get('success_header'),
		success_body: urlParams.get('success_body'),
		api_url: env,
	}

	// const [initialize, setInitialize] = useState(null)
	// const initializeRun = async () => {
	// 	var initializeData = null

	// 	if (!config.api_key) {
	// 		setInitialize({
	// 			status: 'fail',
	// 			fail_message: 'No API Key present.',
	// 		})
	// 		console.log('initialize FAILED to initialize - No API Key. UrlParams: ', urlParams)
	// 		return
	// 	}

	// 	try {
	// 		//const { data } = await _widgetInitialize(
	// 		const { data } = await axios.post(config.api_url + '/CometAPI/Widgets/initialize', {
	// 			api_key: config.api_key,
	// 			type: config.type,
	// 			options: config.options, // this is how we'll control merchant applications based on templates.
	// 		})
	// 		initializeData = data
	// 	} catch (e) {
	// 		initializeData = {
	// 			status: 'fail',
	// 			fail_message: 'Fetch failed.',
	// 		}
	// 	}
	// 	console.log('/initialize complete - ', initializeData)
	// 	setInitialize(initializeData)
	// }

	const [submitResponse, setSubmitResponse] = useState(null)
	const onComplete = async (vals) => {
		// send completed vals to deepstack, get response, send back to widget.
		//console.log('DF - onComplete - WARNING INSECURE DATA TO CONSOLE HERE', vals)
		//console.log('DF - onComplete - mocking call to deepstack.io/submit-validate')

		var ajaxData = {}
		if (config.type == 'card') {
			ajaxData = {
				session_id: config.session_id,
				payment_instrument: {
					type: 'card',
					...vals,
					card_number: vals.card_number && vals.card_number.value,
					card_expiration: vals.card_expiration && vals.card_expiration.value.replace(' / ', ''),
					card_cvv: vals.card_cvv && vals.card_cvv.value,
				},
			}
		} else if (config.type == 'ach') {
			ajaxData = {
				session_id: config.session_id,
				payment_instrument: {
					type: 'bank_account',
					...vals,
					// bank_account_name: 'John Doe',
					// bank_account_number: '12345668',
					// bank_account_routing: '0123456789',
					// bank_account_type: 'checking|savings',
				},
			}
		}

		if (
			(config.options && config.options.risk === true) ||
			!config.options ||
			!Object.keys(config.options).includes('risk') ||
			(Object.keys(config.options).includes('risk') && config.options.risk === true)
		) {
			ajaxData['risk'] = riskData
		}

		console.log('DF - onComplete - submit ajaxData ready', ajaxData)

		var submitData = null
		try {
			const { data } = await axios.post(config.api_url + '/CometAPI/Widgets/doit', ajaxData)
			submitData = data
			setSubmitResponse(data)
			//console.log('/submit complete - ', submitData)
		} catch (e) {
			submitData = {
				status: 'fail',
				fail_message: 'The request to DeepstackWidgetSubmit endpoint failed: ""',

				session_id: 'e32b9041-34b5-4fa9-9190-c46d52d3db11',
				hash: 'InNlc3Npb25faWQiIDogImUzMmI5MDQxLTM0YjUtNGZhOS05MTkwLWM0NmQ1MmQzZGIxMSIsInNlc3Npb25faWQiIDog',
				response: {
					// this will be different depending on the type of request (cc/ach/merchant)
				},
				error_fields: {
					cc_number: 'Invalid card number',
				},
			}
		}

		//console.log('DF - onComplete - response', submitData)

		window.parent.postMessage(
			{
				action: 'submit_complete',
				response: submitData, // random token, replace with response from deepstack
			},
			'*'
		)
	}

	useEffect(() => {
		const sendHeight = () => {
			window.parent.postMessage(
				{
					action: 'form_height',
					height: height,
				},
				'*'
			)
		}

		//console.log('DF - height updated', height)
		if (!height) return
		sendHeight()
	}, [height])

	useEffect(() => {
		//console.log('DF - width updated', width)
	}, [width])

	useEffect(() => {
		// run initialize
		//initializeRun()

		// listen for incoming messages
		window.addEventListener('message', function (e) {
			const data = e.data
			if (data.action === 'submit') {
				console.log('DF - postMessage recieved, triggering submit from external button')
				if (formSubmitRef.current) formSubmitRef.current.submit()
			} else if (data.action === 'risk_update') {
				//console.log('DF - postMessage recieved, triggering risk_update', e, data)
				setRiskData(data.risk)
			} else {
				console.log('DF - postMessage recieved, uncategorized action', e)
			}
		})
	}, [])

	//const formType = 'app_standard'
	return (
		<AppStyled id="deepstack-forms" brand_color={config.brand_color}>
			<GlobalStyle />

			<div id="deepstack-forms-wrapper" ref={appRef}>
				{/*initialize && initialize['status'] == 'fail' ? (
					<div id="deepstack-form-loading">
						<h4>Failed Initialization.</h4>
						<h6>{initialize['fail_message']}</h6>
					</div>
				) : config && initialize && initialize['status'] == 'success' ? (
					<>
						{config.css && <link rel="stylesheet" type="text/css" href={config.css} />}
						<div id="deepstack-form">
							<DeepstackForm {...config} onComplete={onComplete} formSubmitRef={formSubmitRef} />
						</div>
					</>
				) : (
					<div id="deepstack-form-loading">
						<BarLoader />
					</div>
				)*/}

				{config ? (
					<>
						{config.css && <link rel="stylesheet" type="text/css" href={config.css} />}
						<div id="deepstack-form">
							<DeepstackForm {...config} onComplete={onComplete} formSubmitRef={formSubmitRef} />
						</div>
					</>
				) : (
					<div id="deepstack-form-loading">
						<BarLoader />
					</div>
				)}

				{submitResponse && submitResponse['status'] == 'success' && (
					<div id="deepstack-form-message">
						<div id="deepstack-form-message-content">
							<i>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
									<path d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z" />
								</svg>
							</i>
							<div id="deepstack-form-message-text">
								<h4>{config.success_header || 'Thank You!'}</h4>
								<h6>{config.success_body || "We've received your information."}</h6>
							</div>
						</div>
					</div>
				)}
			</div>
		</AppStyled>
	)
}

ReactDOM.render(<App />, document.getElementById('root'))
