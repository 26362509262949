import { useEffect } from 'react'
import DeepstackFormCard from './card'
import DeepstackFormACH from './ach'
import DeepstackFormApplication from './application'

const invalidCombos = [
	{ type: 'card', layout: 'standard', theme: 'minimal' },
	{ type: 'ach', layout: 'standard', theme: 'minimal' },
	{ type: 'application', layout: 'inline', theme: 'minimal' },
	{ type: 'application', layout: 'inline', theme: 'default' },
	{ type: 'application', layout: 'standard', theme: 'minimal' },
]

const DeepstackForm = (props) => {
	useEffect(() => {
		//console.log('DF - Form initializing... ', JSON.stringify(props))
	}, [props])

	var valid = true
	invalidCombos.forEach((item, i) => {
		if (item.type === props.type && item.layout === props.layout && item.theme === props.theme) {
			valid = false
		}
	})
	if (!valid) {
		return (
			<div id="deepstack-form-loading">
				<h4>DeepStack Widget Not Found</h4>
				<h6>Type / Layout / Theme Mismatch</h6>
			</div>
		)
	}
	return (
		<>
			{props.type === 'card' ? (
				<DeepstackFormCard {...props} />
			) : props.type === 'ach' ? (
				<DeepstackFormACH {...props} />
			) : props.type === 'application' ? (
				<DeepstackFormApplication {...props} />
			) : null}
		</>
	)
}
export default DeepstackForm
